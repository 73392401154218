import React, { Component } from 'react'
import { injectIntl, Link } from "gatsby-plugin-intl"

import LayoutStatic from '../../components/LayoutStatic'

class InfoForUserSurvey extends Component {
    render() {
        const title = '片耳難聴者を対象としたミュージックプレーヤーアプリのユーザー調査'
        return (
            <LayoutStatic {...this.props} title={title} 
                ogp={{
                    image: "/images/survey2@2x.jpg", 
                    twitterImage: "/images/survey2@2x.jpg", 
                    width: "1536", 
                    height: "949"
                }}>
                <h2 className="info">{title}</h2>
                <img src="/images/survey2.jpg" className="contentImage" data-srcset="/images/survey2.jpg 1x, /images/survey2@2x.jpg 2x" alt="ユーザー調査" style={{ width: "100%", height: "auto" }} id="featuredImage" />
                <p>当サービスでは片耳難聴者の方を対象としたユーザー調査（アンケート）を行っています。日常生活や音楽の楽しみ方についてお聞きするもので、New Monauralをお使いになったことがなくても回答いただける内容です。</p>
                <p>アンケート結果はNew Monauralの開発・運営の参考にさせていただきます。</p>
                <p className="gotoForm"><a href="https://docs.google.com/forms/d/e/1FAIpQLSdQyRaDF8ybtZeEQlOQbotuKHJw7wzWz9GtQn6Ab3qhrWQVYg/viewform"><img src="/images/file-edit-solid.svg" className="inlineIcon" alt="フォーム" />アンケートフォームへ</a></p>
                <ul className="news">
                <li>New Monauralの開発コンセプトについては、当サイトの<Link to="/mission/">ミッション</Link>またはこちらの<a href="https://note.com/hironobukimura/n/n734c25eae176">note記事</a>でご覧いただけます。</li>
                <li>ご不明な点は<Link to="/support/">サポートフォーム</Link>からお問い合わせください。</li>
                </ul>
            </LayoutStatic>
        )
    }
}

export default injectIntl(InfoForUserSurvey)
